import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import ipad from "../img-fr/bonuses/vdl-church-ipad2.png"
import Loader from "./loader"

const formStyle = {
  marginBottom: "1rem",
}

const imgStyle = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "100%",
}

const formAPI = "/.netlify/functions/convertkit"

const ChurchForm = props => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [inputs, setInputs] = useState({
    inputNom: "",
    inputPrenom: "",
    inputEmail: "",
  })
  const [error, setError] = useState(null)

  function handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    setInputs({
      [name]: value,
    })
    console.log(inputs)
  }

  function formHandler(email, nom, prenom) {
    const formData = {
      email: email,
      lname: nom,
      fname: prenom,
      tags: [5292192],
    }

    return axios({
      method: "post",
      url: formAPI,
      data: formData,
      responseType: "json",
    })
  }

  function handleSubmit(event) {
    setSubmitting(true)
    setError(null)
    console.log("submitted")
    event.preventDefault()

    formHandler(inputs.inputEmail, inputs.inputNom, inputs.inputPrenom)
      .then(() => {
        setSubmitting(false)
        navigate("/uploads/Être un leader visionnnaire pour l'église.pdf")
      })
      .catch(err => {
        setSubmitting(false)
        setError(
          "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer.",
        )
        console.error(err)
      })
  }

  return (
    <div>
      <img src={ipad} style={imgStyle} width="300" alt={""} />
      <p style={{ textAlign: "center" }}>&nbsp;</p>
      <form style={{ position: "relative" }}>
        {isSubmitting && <Loader />}
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        <div className="form-row">
          <div className="grid-50" style={formStyle}>
            <label htmlFor="inputNom">Nom</label>
            <input
              type="text"
              className="form-control"
              id="inputNom"
              name={"inputNom"}
              placeholder="Nom"
              onChange={event => handleChange(event)}
            />
          </div>
          <div className="grid-50" style={formStyle}>
            <label htmlFor="inputPrenom">Prénom</label>
            <input
              type="text"
              className="form-control"
              id="inputPrenom"
              name={"inputPrenom"}
              placeholder="Prénom"
              onChange={event => handleChange(event)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="grid-50" style={formStyle}>
            <label htmlFor="inputEmail">Email</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              name={"inputEmail"}
              placeholder="vous@votreemail.com"
              onChange={event => handleChange(event)}
            />
          </div>
        </div>
        <div className="grid-100" style={{ textAlign: "center" }}>
          <button
            type="submit"
            className="button"
            onClick={event => handleSubmit(event)}
          >
            Télécharger
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChurchForm
