import React from "react"
import { Link } from "gatsby"
import ChurchForm from "../components/church-form"
import Footer from "../components/footer"

const Eglise = props => {
  return (
    <div className="page-template-default">
      <div id="page" className="site">
        <header id="masthead" className="site-header">
          <div className="grid-container">
            <div className="site-branding">
              <p className="site-title">
                <Link to={'/'} rel="home">
                  Être un leader visionnaire
                </Link>
              </p>
            </div>
          </div>
        </header>

        <div id="content" className="site-content">
          <div id="primary" className="content-area">
            <main id="main" className="site-main">
              <article
                id="post-197"
                className="post-197 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">
                    Être un leader visionnaire pour l'église
                  </h1>
                </header>
                <div className="entry-content">
                  <ChurchForm />
                </div>
              </article>
            </main>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}


export default Eglise
